// import React, { Component } from 'react'
import React, { useState, useEffect } from 'react';
import { cmsArticleDetail } from '../../api/column'
import { useParams, NavLink } from 'react-router-dom'
import './Article.css'
export default function Article() {
    //    const typeList=['商会活动','重要资讯','研究交流']
    const typeList = [{ name: '商会活动', key: '1', value: 1, path: "/channel1?channelCode=1" },
    { name: '重要资讯', key: '4', value: 2, path: "/channel2?channelCode=2" },
    { name: '研究交流', key: '2', value: 3, path: "/channel3?channelCode=3" },
    ]
    let [detailContent, setDetailContent] = useState(0)
    let { id, type } = useParams();
    type = Number(type)
    // console.log(type,id)
    useEffect(() => {
        cmsArticleDetail(id).then(res => {
            setDetailContent(res)
        })
    }, [])
    return (
        <div className="channel-body">
            <p className='content-subtitle f16 c6'> / <NavLink className="Breadcrumbs" to={typeList[type - 1].path}> {typeList[type - 1].name}</NavLink>  / 文章详情</p>
            <div className="article-body">
                <div className="art-title">
                    {detailContent.title}
                </div>
                <div className="art-subtitle">
                    <span style={{ marginRight: "50px" }}  className="art-date">
                        {/* <img style={{ marginBottom: '6px', marginRight: '9px' }} src={time} alt=" " /> */}
                        {detailContent.publishedTime}
                    </span>
                    <span className="art-read">
                        {/* <img style={{ marginBottom: '6px', marginRight: '9px' }} src={eye} alt=" " /> */}
                        {detailContent.readTimes}
                    </span>

                </div>
                <div className="article-content">
                    <span dangerouslySetInnerHTML={{ __html: detailContent.content }}></span>
                </div>
            </div>
        </div>
    )
}
