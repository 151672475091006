import React, { Component } from 'react'
import { findCmsDevelopInfo } from '../../../api/aboutUs'
import arrow from '../../../assets/image/icon-15.png'
export default class Develop extends Component {
    state={
        developInfoList: [],
        colorList:['#D22F7F','#D75F2E','#EFBB46','#99C043','#4AA498','#2C6FC4','#9E209E'],
        type: [{ key: '0', name: '' },
        { key: '1', name: '活动' },
        { key: '2', name: '主要会议' },
        { key: '3', name: '发展印迹' },
        { key: '4', name: '论坛' },
        { key: '5', name: '游学参考' },
        { key: '6', name: '行业交流' },
        { key: '7', name: '培训' },
        { key: '8', name: '会员发展' }
        ]
    }
    componentDidMount = e => {
        this.getDevelopInfo()
    }
    getDevelopInfo() {
        findCmsDevelopInfo().then(res => {
            // console.log(res)
            this.setState({
                developInfoList: res,
            })
        })
    }
    render() {
        const {developInfoList,colorList,type} =this.state
        return (
            <div className="develop">
            <div className="label">发展历程</div>  
            <div className='timeline-box'>
                { developInfoList ?
                    developInfoList.map((item, index) => {
                        return <div className='timelin-item' key={index}>
                            <div className='timeline-item-tail'>
                            </div>
                            <div className='timeline-item-idot1' style={developInfoList.length > 7 ? { background: colorList[index % 7] } : { background: colorList[index] }}>
                            </div>
                            <div className='timeline-item-line'>
                            </div>
                            <div className='timeline-item-idot2' style={developInfoList.length > 7 ? { background: colorList[index % 7] } : { background: colorList[index] }}>
                            </div>
                            {item.cmsDevelopInfoList.length>=3?<div className="time-item-arrow" style={developInfoList.length > 7 ? { background: colorList[index % 7] } : { background: colorList[index] }} >
                                {/* <div className="arrow"> </div> */}
                                <img className="arrow" src={arrow}  alt="." />
                            </div>:""}
                            
                            <div style={developInfoList.length > 7 ? { background: colorList[index % 7] } : { background: colorList[index] }} className={'timeline-item-time'}> {item.developYear}</div>
                            <div style={{paddingTop:"10px"}}>
                                <div className='timeline-item-conten'>
                                {
                                    item.cmsDevelopInfoList ? item.cmsDevelopInfoList.map(j => {
                                        return <div className='conten-item' key={j.cmsDevelopInfoId}>
                                            <span style={developInfoList.length > 7 ? { color: colorList[index % 7] } : { color: colorList[index] }} className='date' >
                                                {j.startDate.split(' ')[0]}{j.endDate ? ' ~ ' + j.endDate.split(' ')[0] : ''}
                                            </span>
                                            <div className='text'>
                                                {
                                                    type.map(item => {
                                                        return item.key === j.type && item.key !== '0' ? <span key={item.key} className='tag'>{item.name}</span> : ''
                                                    })
                                                }
                                                {j.developInfo}
                                            </div>
                                        </div>
                                    }) : ''
                                }
                                </div>
                            </div>
                        </div>
                    }) : ''
                }
            </div>
            </div>
        )
    }
}
