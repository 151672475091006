import React, { Component } from 'react'
import { findIntroduce } from '../../../api/aboutUs'
import '../AboutUs.css'
export default class Introduction extends Component {
    state={
        introduceInfo:{}
    }
    componentDidMount = e => {
        this.getIntroduce()
      }
    getIntroduce() {
        findIntroduce().then(res => {
        this.setState({
            introduceInfo: res
        })
        })
    }
    render() {
        const {introduceInfo } = this.state
        return (
            <div className="a-introduction">
                <div className="label">商会简介</div>
                <div className="introduction-box">
                    <div className="brief">

                        <div className="name"><span>{introduceInfo.title}</span></div>
                        <div className="subname">
                            BRIEF <br/> INTRODUCTION
                        </div>
                    </div>
                    <div className="introduction-text">
                        {introduceInfo.content}
                    </div>
                </div>
            </div>
        )
    }
}
