import React, { Component } from 'react'
import MemberItem from '@/components/MemberItem/index'
import "@/assets/css/members.less"
import { Row, Col } from "antd";

import { findCmsMemberInfoList } from '@/api/index'
import InfiniteScroll from 'react-infinite-scroller';
export default class Members extends Component {
    state = {
        memberList: [],
        pageIndex: 1,
        pageSize: 6,
        totalPages: null,
        loading: false,
    }
    componentDidMount() {
        this.getMemberList()
    }
    onReachBottom(){
        // console.log("ceshi",this.state.pageIndex,this.state.totalPages)
        this.getMemberList()
    }
    getMemberList() {
        let param = {
            pageIndex: this.state.pageIndex,
            pageSize: this.state.pageSize
        }
        findCmsMemberInfoList(param).then(res => {
            if(res.data.length!==0){
                this.setState({
                    memberList: this.state.memberList.concat(res.data),
                    totalPages: res.totalPages,
                    loading:true,
                    pageIndex: this.state.pageIndex + 1,
                })
            }else{
                this.setState({
                     loading:false
                   })
            }
            
        })
    }

    render() {
        const { memberList,loading } = this.state
        return (
            <div className='members-box'>
                <p className='content-subtitle f16 c6'> / 会员之窗</p>
                <div className='members-conten' >
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={()=>this.onReachBottom()}
                        hasMore={loading?true: false}
                        loader={<div className="loader" key={0}>Loading ...</div>}
                    >
                        <Row>
                            {
                                memberList ? memberList.map(item => {
                                    return <Col className='members-swiper-item' span={8} key={item.cmsMemberId}><MemberItem menberInfoDetail={item}  /></Col>
                                }) : '暂无数据'
                            }
                        </Row>
                    </InfiniteScroll>
                </div>
                {/* <div className='meb-btn-box'>
                    {
                      pageIndex!==totalPages?<Button type='primary' onClick={()=>this.onReachBottom()} className='meb-btn f16 df'>查看更多</Button>:<span className='f14 c9'>到底啦</span>
                    }
                </div> */}
            </div>
        )
    }
}
