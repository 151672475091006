import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon } from 'antd';
// import { ArrowRightOutlined} from '@ant-design/icons';
import { cmsArticleList, cmsByCodeDetail } from '../../api/column'
import './Channel.css'
export default class Channel extends Component {
    state = {
        articleList: [],
        totalPages: null,
        pageIndex: 1,
        pageSize: 10,
        show: false,
        channel: {},
        channelCode: 1
    }
    componentDidMount = () => {

        this.setState({
            channelCode: this.props.match.path.substr(this.props.match.path.length - 1, 1)
        })
        this.getArticleList();
        this.getCodeDetail();
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        var channelCode = nextProps.match.path.substr(nextProps.match.path.length - 1, 1)
        if (channelCode !== prevState.channelCode) {
            return { channelCode: channelCode }
        } else {
            return null
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // console.log('现在state里的 channelCode', this.state.channelCode)
        if (prevProps.match.path.substr(prevProps.match.path.length - 1, 1) !== this.state.channelCode) {
            this.setState({
                articleList: [],
                totalPages: null,
                pageIndex: 1,
                pageSize: 10,
                show: false,
                channel: {},
            })
            this.getCodeDetail()
            this.getArticleList()
        }
    }
    loadMore = () => {
        // console.log('state',this.state)
        if (this.state.pageIndex < this.state.totalPages) {
            this.setState({
                pageIndex: this.state.pageIndex + 1
            }, () => { this.getArticleList() })
        } else {
            // console.log('meiyoule')
        }

    }
    getArticleList = () => {
        // console.log('!')
        let param = {
            channelCode: this.state.channelCode,
            pageIndex: this.state.pageIndex,
            pageSize: this.state.pageSize
        }
        cmsArticleList(param).then(res => {
            this.setState({
                articleList: this.state.articleList.concat(res.data),
                // articleList: res.data,
                totalPages: res.totalPages
            })
            if (this.state.pageIndex >= this.state.totalPages) {
                this.setState({
                    show: false
                })
            } else {
                this.setState({
                    show: true
                })
            }
            // console.log('!',this.state.articleList)
        })
    }
    getCodeDetail() {
        cmsByCodeDetail(this.state.channelCode,).then(res => {
            // console.log('@@@',res)
            this.setState({ channel: res })
        })
    }
    render() {
        // console.log("render",this)
        const { articleList, show, channel } = this.state
        let button = null
        if (show) {
            button = <Button className="more-btn" type="primary" onClick={this.loadMore}>查看更多</Button>
        } else {
            button = <span className='f14 c9'>到底啦</span>
        }
        return (
            <div className="channel-body">
                {/* <div className="channel-name">
                        <img style={{height:'22px',widht:'22px',marginBottom: '5px'}} src={sy} alt=" "></img>／{channel.channelName}
                </div> */}
                <p className='content-subtitle f16 c6'> / {channel.channelName}</p>
                <div className="channel-list">
                    {articleList ? articleList.map((item) => {
                        return (
                            <div className="article" key={item.cmsArticleId}>
                                <Link to={'/article/' + this.state.channelCode + '/' + item.cmsArticleId}>
                                    <div className="article-img">
                                        <img style={{ width: '350px', height: '360px' }} src={item.mainPic} alt="" />
                                    </div>
                                </Link>
                                <div className="article-content1">
                                    <Link to={'/article/' + this.state.channelCode + '/' + item.cmsArticleId}>
                                        <div className="article-title">{item.title}</div>
                                        {/* <div className="article-subtitle">
                                            {item.subtitle}
                                        </div> */}

                                        <div className="article-op"  >
                                            <div  >
                                                查看详情
                                                <Icon type="arrow-right" style={{ fontSize: '15px', marginLeft: '10px' }} />
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="footer">
                                        <div className="article-date">
                                            {/* <img style={{ marginBottom: '6px', marginRight: '9px' }} src={time} alt="icon" /> */}
                                            {item.publishedTime.slice(0, -9)}
                                        </div>
                                        <div className="article-read">
                                            {/* <img style={{ marginBottom: '6px', marginRight: '9px' }} src={eye} alt="icon" /> */}
                                            {item.readTimes}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <span className='f14 c9'>暂无数据</span>
                    }
                </div>
                <div className="channel-footer">
                    {button}
                </div>
            </div>
        )
    }
}
