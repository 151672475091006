import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Row, Col } from "antd";
import '@/assets/css/footer.less'
import { findCmsContactUsInfo } from '@/api/aboutUs'
import recordPic from '@/assets/image/record.png'

export default class Footer extends Component {
    state = {
        menuList: [
            { name: '首页', key: '0', value: 0, path:"/index"},
            { name: '商会活动', key: '1', value: 1, path:"/channel1?channelCode=1"},
            { name: '研究交流', key: '2', value: 3, path:"/channel3?channelCode=3"},
            { name: '会员之窗', key: '3', value: 4, path:"/members"},
            { name: '重要资讯', key: '4', value: 2, path:"/channel2?channelCode=2"},
            { name: '关于我们', key: '5', value: 5, path:"/aboutUs"}
        ],
        contactUsInfo: {},
    }
    componentDidMount = e => {
        this.getContactUsInfo()
    }
    getContactUsInfo() {
        findCmsContactUsInfo().then(res => {
            this.setState({
                contactUsInfo: res
            })
        })
    }
    render() {
        const { menuList,contactUsInfo} = this.state
        return (
            <div className='footer-box'>
                <Row className='footer-content'>
                    <Col span={9} className='footer-beian f14 c9'>
                           <a target="_blank" href="https://beian.miit.gov.cn"><span class="record-text">备案号：沪ICP备16015082号-2</span></a>
                          <br/><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006617" ><p class="record_content"><img src={recordPic} class="record_img"/>沪公网安备 31010602006617号</p ></a>
                    </Col>
                    <Col span={13}>
                        <ul className='footer-menu'>
                            {
                                menuList.map(item => {
                                    return  <li key={item.value}><NavLink target = "_blank" to={item.path} > {item.name} </NavLink> </li>
                                })
                            }
                        </ul>
                    </Col>
                    <Col span={2}>
                        <div className='wx-code'><img src={contactUsInfo.wxAccountQrPic} alt='' /><span className='f14 text c9'>微信公众号</span></div>
                    </Col>
                </Row>
            </div>
        )
    }
}
