import React, { Component } from 'react'
import { findPrimaryJob } from '../../../api/aboutUs'
import { Row, Col ,Button} from 'antd';
export default class PrimaryJob extends Component {
    state = {
        primaryJobList: [],
        pageIndex: 1,
        pageSize: 9,
        totalPages: null,
        accordActive: null,
        show:false,
    }
    componentDidMount = e => {
        this.getPrimaryJob()
    }
    loadMore(){
        if (this.state.pageIndex < this.state.totalPages) {
            this.setState({
                pageIndex: this.state.pageIndex + 1
            }, () => { this.getPrimaryJob() })
        } 
    }
    getPrimaryJob() {
        let param = {
            pageIndex: this.state.pageIndex,
            pageSize: this.state.pageSize
        }
        findPrimaryJob(param).then(res => {
            res.data.forEach(d=>{
                d.showCon = false
            })
            this.setState({
                primaryJobList:this.state.primaryJobList.concat(res.data),
                totalPages: res.totalPages
            })
            if (this.state.pageIndex >= this.state.totalPages) {
                this.setState({
                    show: false
                })
            } else {
                this.setState({
                    show: true
                })
            }
        })
    }
    mOut(){
        // console.log('oyt')
        this.state.primaryJobList.forEach(a=>{
            a.showCon = false
        })
        this.setState({
            primaryJobList:this.state.primaryJobList
        })
    }
    mOver(e){
        // console.log('mOver',e)
        this.state.primaryJobList.forEach(a=>{
            if(a.cmsPrimaryJobId===e.cmsPrimaryJobId){
                a.showCon=!e.showCon
            }
        })
        // console.log(list)
        this.setState({
            // primaryJobList: this.state.primaryJobList.concat(res.data),
            primaryJobList:this.state.primaryJobList
        })
    }
    render() {
        const {primaryJobList,show} =this.state
        return (
            <div>
                <div className="primary-job">
                    <div className="label">主要工作</div> 
                        <Row className="job-list" gutter={36}>
                        {primaryJobList.map(e=>{
                            return (
                                <Col className="job-box" span={8} key={e.cmsPrimaryJobId} onMouseOver={()=>{this.mOver(e)}} onMouseOut={()=>{this.mOut(e)}}>
                                    <div className="gutter-box">
                                    {e.showCon===false?
                                    <div>
                                        <img className="job-pic" src={e.jobLogo} alt=" "></img>
                                        <div className="job-title">{e.jobTitle}</div>
                                    </div>
                                    :
                                    <div className="Over-jobcontent" >
                                       <div className="line1">{e.jobTitle}</div> 
                                       <div className="line2">{e.jobDetail}</div> 
                                    </div>
                                    }
                                    </div>
                                </Col>
                            )
                        })}
                    </Row> 
                   
                    <div style={{textAlign:"center",margin:"40px 0 100px 0"}}>
                        {show?<Button type="primary" className='members-company-btn text-btn' onClick={()=>{this.loadMore()}}>查看更多</Button>:""}
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        )
    }
}
