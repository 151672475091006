import React, { Component } from 'react'
import { Carousel } from 'antd';
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd';

export default class Exchange extends Component {
    // constructor(props) {
    //     super(props);
    //   }
    state = {
        current: 0,
        mainPic: this.props.exchangeList[0]['mainPic'],
        carouselProps: {
            dots: true,
            dotPosition: 'left',// ant4 生效
            dotsClass: 'slick-dots slick-dots-left',
            vertical: true,// ant2 生效
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            afterChange: (val) => {
                const curMainPic = this.props.exchangeList[val]['mainPic'];
                this.setImage(curMainPic)
            }
        }
    }
    componentDidMount() {
       
    }
    setImage(curMainPic) {
        this.setState({
            mainPic: curMainPic
        })
    }
    render() {
        const { exchangeList } = this.props;
        const { carouselProps, mainPic } = this.state
        return (
            <div className='exchange'>
                {
                    exchangeList && exchangeList.length ?
                        <Row >
                            <Col span={13} className='ex-left-box'>
                                <h3 className='title f36 cf'>研究交流</h3>
                                <Carousel autoplay={exchangeList.length>1?true:false} {...carouselProps} >
                                    {
                                        exchangeList.map(item => {
                                            return <div key={item.cmsArticleId}>
                                                <Link to={'/article/3/' + item.cmsArticleId}>
                                                    <div className='ex-item-slide'>
                                                        <div className='ex-date f18'>{item.title}</div>
                                                        <p className='ex-text f14 c9'>{item.subtitle}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        })
                                    }
                                </Carousel>
                            </Col>
                            {
                                mainPic !== "" ?
                                    <Col span={11} className='ex-pic-box'>
                                        <img className='ex-pic' alt='' src={mainPic} />
                                    </Col> : ""
                            }
                        </Row> : ''
                }
            </div>
        )
    }
}
