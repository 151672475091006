import request from '../utils/request.js'
const siteId=210326170830001
// 商会简介
export function findIntroduce() {
  return request({
    url: '/api/cms/outsite/findCmsIntroduceInfo',
    method: 'get',
    params:{
        cmsSiteId:siteId
      }
  })
}
//主要工作列表
export function findPrimaryJob(data) {
    data.cmsSiteId=siteId
    return request({
      url: '/api/cms/outsite/findCmsPrimaryJobInfo',
      method: 'get',
      params:data
    })
}
//主要工作详情
export function findPrimaryJobDetail(data) {
    return request({
      url: '/api/cms/cmsPrimaryJob/get/'+data,
      method: 'get',
    })
  }
//下属机构
export function findCmsSubOrgInfo() {
    return request({
      url: '/api/cms/outsite/findCmsSubOrgInfo',
      method: 'get',
      params:{
        cmsSiteId:siteId
      }
    })
}
//联系方式
export function findCmsContactUsInfo() {
    return request({
      url: '/api/cms/outsite/findCmsContactUsInfo',
      method: 'get',
      params:{
          cmsSiteId:siteId
        }
    })
  }
// 发展历程
export function findCmsDevelopInfo() {
  return request({
    url: '/api/cms/outsite/findCmsDevelopInfo',
    method: 'get',
    params:{
        cmsSiteId:siteId
      }
  })
}
