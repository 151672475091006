import React, { Component } from 'react'
import { Carousel, Button } from 'antd';
import { Link } from 'react-router-dom'
import activeIcon from '@/assets/image/icon-5.png'
export default class Activity extends Component {
    state={
        carouselProps: {
            autoplay: true,
            speed: 300,
            infinite:true
        }
    }
    render() {
        const { channelArticle } = this.props.activityList
        return (
            <Carousel {...this.state.carouselProps}>
                {
                    channelArticle ? channelArticle.map(item => {
                        return <div className='ac-swiper-box' key={item.cmsArticleId}>
                            <div className='img-box-log'>
                                <img className='item-img' alt='' src={activeIcon} />
                            </div>
                            <div className='item-title f24 c3'>{item.title}</div>
                            <div className='item-content-detail c6 f14'>{item.subtitle}</div>
                            <Link to={'/article/1/' + item.cmsArticleId}><Button className='item-btn f16' type='primary'>查看详情</Button></Link>
                        </div>
                    }) : ''
                }
            </Carousel>
        )
    }
}
