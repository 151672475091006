import React, { Component } from 'react'
import { Row, Col } from "antd";

import '@/assets/css/home/consulting.less'

export default class Consulting extends Component {
    render() {
        const cmsContactUs=this.props.cmsContactUs
        return (
            <div>
                {
                    cmsContactUs?<Row className='consulting-content'>
                    <Col span={11} className='consulting-code-box'>
                        <div className='wx-code f16 cf'>
                            <img className='wx-code-img' alt='' src={cmsContactUs.wxAccountQrPic}/>
                            微信公众号
                        </div>
                        <div className='wx-code f16 cf'>
                            <img className='wx-code-img' alt='' src={cmsContactUs.wxEnterpriseWxPic}/>
                            企业公众号
                        </div>
                    </Col>
                    <Col span={12} offset={1}>
                        <div className='contact-info'>
                            <div className='contact-info-item'>
                                <p className='f18 cf contact-info-category phone'>联系电话</p>
                                <div className='contact-info-text f16'>
                                    <p className='contact-info-title f16'>电话</p>:
                                    <p className='contact-info-number f16'>{cmsContactUs.phone}</p>
                                </div>
                                <div className='contact-info-text f16'>
                                    <p className='contact-info-title f16'>传真</p>:
                                    <p className='contact-info-number f16'>{cmsContactUs.fax}</p>
                                </div>
                            </div>
                            <div className='contact-info-item'>
                                <p className='f18 cf contact-info-category emil'>电子邮箱</p>
                                <div className='contact-info-text f16'>
                                    <p className='contact-info-title '>公司邮箱</p>:
                                    <p className='contact-info-number'>{cmsContactUs.companyEmail}</p>
                                </div>
                                <div className='contact-info-text f16'>
                                    <p className='contact-info-title '>会长邮箱</p>:
                                     <p className='contact-info-number'>{cmsContactUs.presidentEmail}</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>:''
                }
                

            </div>
        )
    }
}
