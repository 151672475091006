import React, { Component } from 'react'
import { Row, Col } from "antd";
import { Link, NavLink } from 'react-router-dom'
import '@/assets/css/home/information.less'

export default class Information extends Component {
    render() {
        const { channelArticle } = this.props.informationData
        return (
            <div>
                <Row className='info-content'>
                    <Col span={11} className="info-content-left">
                        <Row className="infor-item">
                            <Link to={'/article/2/' + channelArticle[0].cmsArticleId}>
                                <Col span={11} className="infor-item-imgBox">
                                    <img className='infor-item-img' alt='' src={channelArticle[0].mainPic}></img>
                                </Col>
                            </Link>
                            <Col span={12} offset={1} className='infor-item-contrenBox'>
                                <Link to={'/article/2/' + channelArticle[0].cmsArticleId}>
                                    <div className='headlines f30'>头条</div>
                                    <div className='infor-item-top'>
                                        <p className='infor-item-date f24'>{channelArticle[0].publishedTime}</p>
                                        <div className='infor-item-title c3 f18'>{channelArticle[0].title}</div>
                                    </div>
                                    <div className='infor-item-text c6 f14'>{channelArticle[0].subtitle}</div>
                                </Link>
                                <NavLink to='/channel2?channelCode=2' >
                                    <div className='infor-item-more  f16'>了解更多</div>
                                </NavLink>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} className="" offset={1}>
                        <Row className="infor-item">
                            <Link to={'/article/2/' + channelArticle[0].cmsArticleId}>
                                <Col span={6} className="infor-item-imgBox">
                                    <img className='infor-item-img' alt='' src={channelArticle[1].mainPic}></img>
                                </Col>
                            </Link>
                            <Col span={16} offset={2} className='infor-item-contrenBox'>
                                <Link to={'/article/2/' + channelArticle[0].cmsArticleId}>
                                    <div className='infor-item-top'>
                                        <p className='infor-item-date f14'>{channelArticle[1].publishedTime}</p>
                                        <div className='infor-item-title c3 f18'>{channelArticle[1].title}</div>
                                    </div>
                                    <div className='infor-item-text c6 f14'>{channelArticle[1].subtitle}</div>
                                </Link>
                                <NavLink to='/channel2?channelCode=2' >
                                    <div className='infor-item-more  f16'>了解更多 </div>
                                </NavLink>
                            </Col>
                        </Row>
                        <Row className="infor-item">
                            <Link to={'/article/2/' + channelArticle[0].cmsArticleId}>
                                <Col span={6} className="infor-item-imgBox">
                                    <img className='infor-item-img' alt='' src={channelArticle[2].mainPic}></img>
                                </Col>
                            </Link>
                            <Col span={16} offset={2} className='infor-item-contrenBox'>
                                <Link to={'/article/2/' + channelArticle[0].cmsArticleId}>
                                    <div className='infor-item-top'>
                                        <p className='infor-item-date f14'>{channelArticle[2].publishedTime}</p>
                                        <div className='infor-item-title c3 f18'>{channelArticle[2].title}</div>
                                    </div>
                                    <div className='infor-item-text c6 f14'>{channelArticle[2].subtitle}</div>
                                </Link>
                                <NavLink to='/channel2?channelCode=2' >
                                    <div className='infor-item-more  f16'>了解更多 </div>
                                </NavLink>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}
