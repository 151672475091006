
import React, { Component } from 'react'
import axios from 'axios'
const AMap = window.AMap;
// var marker = new AMap.Marker();
var map;

class Map extends Component {

    state = {
        location: null,
        type:true,
    }
    componentDidMount() {
        // console.log('props', this.props)
        map = new AMap.Map('epccmap', {
            zoom: 20,
        });//新建地图
    }
    componentWillReceiveProps(nextProps) {
        nextProps.location !== this.props.location && this.setState({
            location: nextProps.openNotice
        })
        if (nextProps.location&&this.state.type) {
            // var geocoder = new AMap.Geocoder({
            //     radius: 10,
            // });
            axios({
                method:"GET",
                url: 'https://restapi.amap.com/v3/geocode/geo?key=3ae86a54629c5714170a1ff2948cc2ba&address='+nextProps.location,
            }).then(res=>{
                // console.log('mao',res)
                if (res.status === 200&&res.data.geocodes) {
                    let linDta = res.data.geocodes[0].location.split(',')
                    var icon = new AMap.Icon({
                        size: new AMap.Size(20, 30),    // 图标尺寸
                        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',  // Icon的图像
                    });
                    var marker = new AMap.Marker({
                        icon: icon,
                        position: [linDta[0], linDta[1]],
                    });
                    map.setZoomAndCenter(13, [linDta[0], linDta[1]]);
                    marker.setMap(map);
                    this.setState({
                        type:false
                    })
                }
            })

        }
    }
    render() {
        return (
            <div className='map-content'>
                <div id="epccmap" ></div>
            </div>
        )
    }
}

export default Map