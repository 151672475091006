import reqeust from '../utils/request.js'
const siteId=210326170830001
// 栏目列表
export function cmsArticleList(data) {
  //showToast = true, autoLogin = true
  data.cmsSiteId=siteId
  return reqeust({
    url: '/api/cms/outsite/listByChannel',
    method: 'get',
    params: data
  })
}
//重要资讯详情
export function cmsByCodeDetail(data) {
    return reqeust({
      url: '/api/cms/outsite/getByCode/'+siteId+'/'+data,
      method: 'get',
    })
  }
export function cmsArticleDetail(data) {
    return reqeust({
      url: '/api/cms/outsite/read/'+data,
      method: 'get',
    })
}

