// import logo from './logo.svg';
import './App.css';
import '@/assets/css/public.less'
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from '@/redux/store';
import Header from '@/components/Header/index';
import NotFound from '@/pages/NotFound/index';
import ScrollToTop from '@/components/ScrollToTop'
import { Home, Members, Channel, AboutUs, Article } from './pages'
// import Router from './router/index';
import { homeInfo } from '@/api/index'
export default class App extends Component {
  componentDidMount() {
    homeInfo().then(res => {
      // console.log('res', res)
    })
  }
  render() {
    return (
      <div className='content-box'>
        <Provider store={store}>
          <ScrollToTop>
            <Header />
            <Switch>
              <Route path="/index" component={Home} />
              <Route component={Members} path="/members" />
              <Route component={Channel} path="/channel1" />
              <Route component={Channel} path="/channel2" />
              <Route component={Channel} path="/channel3" />
              <Route component={AboutUs} path="/aboutUs" />
              <Route component={Article} path="/article/:type/:id" />
              <Route path="/404" component={NotFound} />
              <Redirect to="/index" from='/' exact />
              <Redirect to="/404" />
            </Switch>
          </ScrollToTop>
        </Provider>
      </div>
    )
  }
}
