import React, { Component } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import '@/assets/css/header.less';
import logo from '@/assets/image/logo.png'
class Header extends Component {
    state = {
        menuList: [
            { name: '首页', key: '0', value: 0, path:"/index"},
            { name: '商会活动', key: '1', value: 1, path:"/channel1?channelCode=1"},
            { name: '研究交流', key: '2', value: 3, path:"/channel3?channelCode=3"},
            { name: '会员之窗', key: '3', value: 4, path:"/members"},
            { name: '重要资讯', key: '4', value: 2, path:"/channel2?channelCode=2"},
            { name: '关于我们', key: '5', value: 5, path:"/aboutUs"}
        ]
    }
    goBack = () => {
        //前进
        this.props.history.goBack()
    }
    goforward = () => {
        //后退
        this.props.history.goForward()
    }
    go = () => {
        //前进或后退 根据数值来定
        this.props.history.go(-1)
    }
    render() {
        const { menuList } = this.state
        return (
            <div className='header-content'>
                <div className='header-fix'>
                    <div className='header-box'>
                    <NavLink className="cf" to='/index'> <img className='header-logo'  alt='' src={logo} /></NavLink>
                        <ul className='header-menu'>
                            {
                                 menuList.map(item => {
                            return <li className='menu-item f16' key={item.key}>
                                        <NavLink target = "_blank" className="cf" activeClassName="selected" to={item.path} > {item.name}  </NavLink>
                                   </li>
                            })
                          }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
//withRouter 可以加工一般组件，使得一般组件拥有路由组件的属性
export default withRouter(Header)
