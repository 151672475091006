import reqeust from '../utils/request.js'
const siteId=210326170830001
// 登录
export function doLogin(data) {
  //showToast = true, autoLogin = true
  return reqeust({
    url: '/api/doLoginFromVue',
    method: 'post',
    data: data
  })
}
//首页信息
export function homeInfo() {
  return reqeust({
    url: '/api/cms/outsite/homepage',
    method: 'get',
    params:{
      cmsSiteId:siteId
    }
  })
}
//会员之窗介绍查询
export function findCmsMemberInfo() {
  return reqeust({
    url: '/api/cms/outsite/findCmsWindowMembersInfo',
    method: 'get',
    params:{
      cmsSiteId:siteId
    }
  })
}
export function findCmsMemberInfoList(data) {
  data.cmsSiteId=siteId
  return reqeust({
    url: '/api/cms/outsite/findCmsMemberInfo',
    method: 'get',
    params:data
  })
}


