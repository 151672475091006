import React, { Component } from 'react'
import Introduction from './components/Introduction'
import Develop from './components/Develop'
import PrimaryJob from './components/PrimaryJob'
import SubOrg from './components/SubOrg'
import ContactWay from './components/ContactWay'
import './AboutUs.css'
export default class AboutUs extends Component {
    state = {
        navMenuList: [
            { name: '商会简介', key: 0, id: 'Introduction' },
            { name: '发展历程', key: 1, id: 'Develop' },
            { name: '主要工作', key: 2, id: 'PrimaryJob' },
            { name: '下属机构', key: 3, id: 'SubOrg' },
            { name: '联系方式', key: 4, id: 'ContactWay' }
        ],
        accordActive: 0,
        accordName: "商会简介",
        menufixed: false,

    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = event => {
        // // 滚动条滚动高度
        const scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
        const fixedTop =  document.getElementById('menu').offsetTop;
        //  const intTop =document.getElementById('Introduction').offsetTop;
        const devTop = document.getElementById('Develop').offsetTop
        const jobTop = document.getElementById('PrimaryJob').offsetTop
        const orgTop = document.getElementById('SubOrg').offsetTop
        const conTop =document.getElementById('ContactWay').offsetTop
        // console.log(scrollTop,fixedTop,intTop,devTop,jobTop,orgTop,conTop)
        if (scrollTop + 110 >= fixedTop) {
            this.setState({ menufixed: true });
        } else if (scrollTop + 110 < fixedTop) {
            this.setState({ menufixed: false });
        }
        if(scrollTop+286<devTop){
            this.setState({accordActive:0})
        }else if(scrollTop+286>=devTop&&scrollTop+286<jobTop){
            this.setState({ accordActive:1})
        }else if(scrollTop+286>=jobTop&&scrollTop+286<orgTop){
            this.setState({ accordActive:2})
        }else if(scrollTop+286>=orgTop&&scrollTop+286<conTop){
            this.setState({ accordActive:3})
        }else if(scrollTop+286>=conTop){
            this.setState({ accordActive:4})
        } 
      
    };
    scrollToAnchor = (anchorName, key, name) => {
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if(anchorElement) {
                //  anchorElement.scrollIntoView({block: 'center', behavior: 'smooth'});
                window.scrollTo(0,anchorElement.offsetTop-270)
            }
            this.setState({
              accordActive: key,
            })
        }
    }
    render() {
        const { navMenuList, accordActive, accordName, menufixed } = this.state
        return (
            <div >
                {/* <div className="channel-name">
                        <img style={{height:'22px',widht:'22px',marginBottom: '5px'}} src={sy} alt=" "></img>／关于我们／商会简介
                </div> */}
                <p className='content-subtitle f16 c6'> / 关于我们 / {accordName}</p>
                <div className="contact-menu" id='menu'>
                    <div className={menufixed ? 'styleFixed' : ''}>
                        <div className={menufixed ? 'contact-menu-content' : ''}>
                            {navMenuList.map((a) => {
                                return (
                                    <div onClick={() => { this.scrollToAnchor(a.id, a.key, a.name) }} className={`menu-items ${accordActive === a.key ? 'active-color' : ''}`} key={a.key}>{a.name} </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div id="Introduction" >
                    <Introduction />
                </div >
                <div id='Develop' >
                    <Develop />
                </div >
                <div id='PrimaryJob' >
                    <PrimaryJob />
                </div>
                <div id='SubOrg'>
                    <SubOrg />
                </div>
                <div id="ContactWay" >
                    <ContactWay />
                </div>
            </div>
        )
    }
}
