import React, { Component } from 'react'
import { findCmsSubOrgInfo } from '../../../api/aboutUs'
export default class SubOrg extends Component {
    state = {
        subOrgInfoList: [],
    }
    componentDidMount = e => {
        this.getSubOrgInfoList()
    }
    getSubOrgInfoList() {
        findCmsSubOrgInfo().then(res => {
            this.setState({
                subOrgInfoList:res
            })
        })
    }
    render() {
        const {subOrgInfoList} =this.state
        return (
            <div  className="sub">
                <div className="sub-org">
                    <div className="label">下属机构</div>
                    <div className="org-title">截止2021年1月，上海市网购商会下属机构有</div>
                    <ul className="org-lsit">
                            {
                                subOrgInfoList.map(item=>{
                                return <li className="org" key={item.cmsSubOrgId}>{item.orgName}</li>
                                })
                            }
                    </ul>
                </div>
            </div>
        )
    }
}
