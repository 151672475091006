const initState = 0
export default function countReducer(perstate = initState, action) {
    const { type, data } = action
    switch (type) {
        case 'increment':
            return perstate + data
        case 'decrement':
            return perstate - data
        default: 
        return perstate
    }
}