import React, { Component } from 'react'
import { Carousel } from 'antd';
import MemberItem from '@/components/MemberItem/index'
import {findCmsMemberInfoList} from '@/api/index'


export default class Exchange extends Component {
    state = {
        current: 0,
        carouselProps: {
            autoplay: true,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
        },
        menberInfoList:[]
    }
    componentDidMount() {
        this.getMemberList()
    }
    getMemberList() {
        let param = {
            pageIndex: 1,
            pageSize: 4
        }
        findCmsMemberInfoList(param).then(res => {
            this.setState({
                menberInfoList: res.data
            })
        })
    }
    currentChange(val) {
        if (this.props.menberInfoList.length > 1) {
            this.setState({
                current: val
            })
        }
    }
    render() {
        const  {menberInfoList} = this.state
        return (
            <div className='members-conten'>
                {
                    menberInfoList && menberInfoList.length ?
                        <Carousel {...this.state.carouselProps}>
                            {
                                menberInfoList.map(item => {
                                    return <div className='members-swiper-item' key={item.cmsMemberId} >
                                        <MemberItem menberInfoDetail={item} />
                                    </div>
                                })
                            }
                        </Carousel>
                        : ''
                }
            </div>
        )
    }
}
