import React, { Component } from 'react'
import { Row, Col, Button } from "antd";
import { Link } from 'react-router-dom'
import Divider from './components/Divider'
import Activity from './components/Activity'
import Exchange from './components/Exchange'
import HomeMembers from './components/Members'
import Information from './components/Information'
import Consulting from './components/Consulting'
import photo02 from '@/assets/image/photo02.png'
import '@/assets/css/home/home.less'
import { homeInfo, findCmsMemberInfo, findCmsMemberInfoList } from '@/api/index'

export default class Home extends Component {
    state = {
        homeDetail: {},
        informationData: [],
        activeData: [],
        exchangeData: [],
        menberInfo: {},
        menberInfoList: [],
    }
    componentDidMount() {
        this.getHomeInfo()
        this.getMember()
        this.getMemberList()
    }
    componentDidShow() {
    }
    getHomeInfo() {
        homeInfo().then(res => {
            this.setState({
                homeDetail: res
            })

            if (this.state.homeDetail.channelInfoList) {
                this.state.homeDetail.channelInfoList.filter(res => {
                    switch (res.channelCode) {
                        case '1':
                            return this.setState({
                                activeData: res
                            })
                        case '2':
                            return this.setState({
                                informationData: res
                            })
                        case '3':
                            return this.setState({
                                exchangeData: res
                            })
                        default:
                            return ''
                    }
                })
            }
        })
    }
    getMember() {
        findCmsMemberInfo().then(res => {
            this.setState({
                menberInfo: res
            })
        })
    }
    getMemberList() {
        let param = {
            pageIndex: 1,
            pageSize: 8
        }
        findCmsMemberInfoList(param).then(res => {
            this.setState({
                menberInfoList: res.data
            })
        })
    }
    render() {
        const { homeDetail, informationData, activeData, exchangeData, menberInfo, menberInfoList } = this.state
        return (
            <div className='home-content'>
                {/* 上海市网购商会 */}
                <div className='mission-content'>
                    <div className='f22 titleEg'>Shanghai E-Purchasing Chamber of Commerce</div>
                    <div className='f80 titleCh'>上海市网购商会</div>
                    <Row className='callout-line'>
                        <Col span={10}><Divider className='at-divider' /></Col>
                        <Col span={4} className='dot'>.</Col>
                        <Col span={10}><Divider className='at-divider' /></Col>
                    </Row>
                    <p className='f14 mission-text'>我们的使命：{homeDetail.cmsIntroduce ? homeDetail.cmsIntroduce.head : ''}</p>
                   <Link to='aboutUs'> <Button className='f18 mission-btn' >详情</Button></Link>
                </div>
                {/* 商会简介 */}
                <div className='introduction'>
                    {
                        homeDetail.cmsIntroduce ?
                            <Row className='item-content'>
                                <Col span={9} className='init-right'>
                                    <img className='intr-img' alt='' src={homeDetail.cmsIntroduce.mainPic} />
                                </Col>
                                <Col span={11} offset={1} className='intr-context'>
                                    <div className='intr-context-title f36 c6'>商会简介</div>
                                    <div className="intr-context-subtitle f24 c6">{homeDetail.cmsIntroduce.subtitle}</div>
                                    <p className='intr-context-text f16 c6'>{homeDetail.cmsIntroduce.content}</p>
                                    <Link to='aboutUs'><Button className='intr-context-btn f18 text-btn' type="primary">了解更多</Button></Link>
                                </Col>
                            </Row>
                            : ''
                    }
                </div>
                {/* 商会活动 */}
                <div className='activity'>
                    <div className='item-title-box'>
                        <h3 className='title f36 c6'>商会活动</h3>
                        <p className='subtitle f14 c9'>{activeData ? activeData.channelDesc : ''}</p>
                        <Row className='callout-line'>
                            <Col span={10}><Divider className='at-divider' /></Col>
                            <Col span={4} className='dot'>.</Col>
                            <Col span={10}><Divider className='at-divider' /></Col>
                        </Row>
                    </div>
                    <div className='item-content'>
                        <div className='left-box'>
                            {
                                activeData?<Activity  activityList={activeData}/>:''
                            }
                        </div>
                        <div className='right-box'>
                            <img className='photo' alt='' src={photo02} />
                        </div>
                    </div>
                </div>
                {/* 研究交流 */}
                {
                    exchangeData&&exchangeData.channelArticle ? (
                        <Exchange exchangeList={exchangeData.channelArticle} />
                    ) : ''
                    }
                {/* 会员之窗 */}
                <div className='members'>
                    <div className='item-title-box'>
                        <h3 className='title f36 c6'>会员之窗</h3>
                        <p className='subtitle f14 c9'>{menberInfo.windowInfo}</p>
                        <Row className='callout-line'>
                            <Col span={10}><Divider className='at-divider' /></Col>
                            <Col span={4} className='dot'>.</Col>
                            <Col span={10}><Divider className='at-divider' /></Col>
                        </Row>
                    </div>
                    {
                        menberInfoList ? <div>
                            <div className='item-content'>
                                <Row className='members-logo-list'>
                                    {
                                        menberInfoList.map(item=>{
                                            return <Col span={6} className="members-logo-item" key={item.cmsMemberId}>
                                            <div className='logo'> <img alt='' src={item.lightLogo} /></div>
                                        </Col>
                                        })
                                    }
                                </Row>
                            </div>
                            <div className='members-company-list'>
                                <HomeMembers menberInfoList={menberInfoList}/>
                                <Link to='members'><Button type="primary" className='members-company-btn text-btn'>更多会员单位</Button></Link>
                            </div>
                        </div> : ''
                    }
                </div>
                {/* 重要资讯 */}
                <div className='information'>
                    <div className='item-title-box'>
                        <h3 className='title f36 c6'>重要资讯</h3>
                        <p className='subtitle f14 c9'>{informationData ? informationData.channelDesc : ''}</p>
                        <Row className='callout-line'>
                            <Col span={10}><Divider className='at-divider' /></Col>
                            <Col span={4} className='dot'>.</Col>
                            <Col span={10}><Divider className='at-divider' /></Col>
                        </Row>
                    </div>
                    <div className='item-content'>
                     {
                      informationData && informationData.channelArticle ? <Information informationData={informationData}/>:''
                      }
                    </div>
                </div>

                {/* 入会咨询 */}
                <div className='consulting'>
                    <div className='item-title-box'>
                        <h3 className='title f36 cf'>入会咨询</h3>
                        <p className='subtitle f14 cf'>我们期待您的加入！</p>
                        <Row className='callout-line'>
                            <Col span={10}><Divider className='at-divider' /></Col>
                            <Col span={4} className='dot'>.</Col>
                            <Col span={10}><Divider className='at-divider' /></Col>
                        </Row>
                    </div>
                    <div className='item-content'>
                        <Consulting  cmsContactUs={homeDetail.cmsContactUs}/>
                    </div>
                </div>
                {/* <MyNavLink to='/home/news'> News</MyNavLink>
                <MyNavLink to='/home/message'>Message</MyNavLink>
                <Switch>
                    <Route path="/home/news" component={News} />
                    <Route path="/home/Message" component={Message} />
                    <Redirect to="/home/news"/>
                </Switch> */}
            </div>
        )
    }
}