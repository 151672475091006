import axios from 'axios'
import { Message } from 'antd';

//创建axios实例
const service = axios.create({
  // baseURL: process.env.BASE_API, // api的base_url
  timeout: 200000, // 请求超时时间
  withCredentials: true // 选项表明了是否是跨域请求
})
service.interceptors.request.use(config => {
  // Message.loading('加载中', 1)              // loading组件，显示文字加载中，自动关闭延时1s
  // console.log('request go');
  return config;
}, err => {
  // console.log('请求失败')
  return Promise.reject(err)
})
//拦截响应
service.interceptors.response.use(config => {
  // console.log('response get')
  return config;
}, err => {
  // console.log('响应失败')
  return Promise.reject(err)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    // console.log('response', response)
    // const res = response.data
    if (response.status !== 200) {
      // console.log("responsessss", res)
      Message.warning('message');
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    return Promise.reject(error)
  }
)
export default service