import React, { Component } from 'react'
import NotFoundimg from '@/assets/image/404.gif'

export default class NotFound extends Component {
    state={
        imgStyle:{
            width:'100%',
            height:'100%'
        }
    }
    render() {
        const {imgStyle}=this.state
        return (
            <div className='content-box'>
                <img style={imgStyle} src={NotFoundimg} alt=' '></img>
            </div>
        )
    }
}
