//ie9+
import "core-js/es";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Footer from '@/components/Footer/index';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
// import stort from "./redux/store";
ReactDOM.render(
  // React.StrictMode
  <>
    <Router>
      <App />
      <Footer />
    </Router>
    
  </>,
  document.getElementById('root')
);
reportWebVitals();
// stort.subscribe(() => {
//   ReactDOM.render(
//     <React.StrictMode>
//       <BrowserRouter>
//         <Router> <App /></Router>
//       </BrowserRouter>
//     </React.StrictMode>,
//     document.getElementById('root')
//   );
// })




