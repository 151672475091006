import React, { Component } from 'react'

export default class Divider extends Component {
    render() {
        return (
            <div className="at-divider ant-divider-horizontal">
                
            </div>
        )
    }
}
