import React, { Component } from 'react'
import '@/assets/css/membersItem.less'
export default class MemberItem extends Component {
    state={
        imgState:false
    }
    hendleOnMouseEnter(e){
    //   console.log("鼠标移入事件",e)
      e.stopPropagation()
      this.setState({
        imgState:true
      })
    }
    handleOnMouseLeave(e){
    //    console.log("鼠标移出事件")
       e.stopPropagation()
       this.setState({
        imgState:false
      })
    }
    render() {
        const {imgState}=this.state
        const menberInfoDetail = this.props.menberInfoDetail
        return (
            <div >
                <div className='members-swiper-item-content' onMouseLeave={(e)=>{this.handleOnMouseLeave(e)}} onMouseEnter={(e)=>{this.hendleOnMouseEnter(e)}}>
                    <div className='top-title '>
                        <p className='title f24 cf'>{menberInfoDetail.abbreviation} </p>
                        <img className='tag' alt='' src={imgState?menberInfoDetail.lightLabel:menberInfoDetail.darkLabel}></img>
                    </div>
                    <div className='bottom-content'>
                        <div className='logo-box'>
                            <div className='circle'></div>
                            <img className='logo' alt='' src={imgState?menberInfoDetail.lightLogo:menberInfoDetail.darkLogo}></img>
                        </div>
                        <div className='text f16 c6'>{menberInfoDetail.name}</div>
                    </div>
                </div>
            </div>
        )
    }
}
