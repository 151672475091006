import React, { Component } from 'react'
import Map from '../../../components/Map/Map'
import { Row, Col } from 'antd';
import { findCmsContactUsInfo } from '../../../api/aboutUs'
export default class ContactWay extends Component {
    state = {
        contactUsInfo: {},
    }
    componentDidMount = e => {
        this.getContactUsInfo()
    }
    getContactUsInfo() {
        findCmsContactUsInfo().then(res => {
            // console.log('cccc',res)
            this.setState({
                contactUsInfo: res
            })
        })
    }
    render() {
        const {contactUsInfo} =this.state
        return (
            <div className="contact-way">
               <div className="label"> 联系方式</div>
               { !!window.ActiveXObject || "ActiveXObject" in window ||navigator.userAgent.indexOf("Edg") > -1 ? <div className="IE-dispaly">暂不支持当前浏览器访问</div> : <Map  location={contactUsInfo.location}/>}
               <div className="con-box">
               <Row >
                <Col span={12} >
                   <div className="con-label lo-icon">商会地址 </div>
                   <div className="cont-text"> 地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：{contactUsInfo.location}</div>
                   <div className="cont-text"> 邮政编码：{contactUsInfo.postalCode}</div>
                   <div className="con-label phone-icon" >联系电话 </div>
                   <div className="cont-text"> 电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：{contactUsInfo.phone}</div>
                   <div className="cont-text"> 传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;真：{contactUsInfo.fax}</div>
                   <div className="con-label em-icon">电子邮箱 </div>
                   <div className="cont-text"> 公司邮箱：{contactUsInfo.companyEmail}</div>
                   <div className="cont-text"> 会长邮箱：{contactUsInfo.presidentEmail}</div>
                </Col>
                <Col span={6}  style={{position:'relative'}}>
                    <div  className="wx">
                    <img className="wx-img" src={contactUsInfo.wxAccountQrPic} alt="微信公众号"/>
                     <div className="wx-text"> 微信公众号</div>
                     </div>
                </Col>
                <Col span={6}style={{position:'relative'}} >
                    <div  className="wx">
                    <img className="wx-img" src={contactUsInfo.wxEnterpriseWxPic} alt="企业微信"/>
                    <div  className="wx-text"> 企业微信</div>
                    </div>
                </Col>
                </Row>
                   

               </div>
            </div>
        )
    }
}
